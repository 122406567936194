import { IconButton } from '@mui/material';
import { Upload } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';

export default function UploadButton({ onUpload, disabled } : {
  disabled: boolean
  onUpload: (file: File) => void
}) {

  const fileInputRef = React.useRef(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
    const files = event.target.files;
    if (files) {
      onUpload(files[0]);
    }
  };

  return (
    <Tooltip title="Upload Content Package">
        <IconButton
            // @ts-ignore
            onClick={() => fileInputRef.current.click()}
            disabled={disabled}
            data-testid={'upload-button'}
        >
            <Upload/>
            <input
                type='file'
                accept='.lscree'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                data-testid={'file-input'}
            />
        </IconButton>
    </Tooltip>
  );
}