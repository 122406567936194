export interface Host {
  url: string,
  stage: string
}

export default function getHost() : Host {
  const origin = window.location.origin;

  if (/local/.test(origin))                           return { url: 'http://localhost:8000', stage: 'local' };
  if (/sandbox|calm-beach-01cffb603/.test(origin))    return buildHost('sandbox');
  if (/dev|kind-coast-0b3d7f903/.test(origin))        return buildHost('dev');
  if (/staging|orange-sand-0407b7f03/.test(origin))   return buildHost('staging');
  if (/prod|calm-dune-0ce31b403/.test(origin))        return buildHost('prod');
  if (origin.includes('cndmo'))                       return buildHostCn('cndmo');
  if (origin.includes('cnapp'))                       return buildHostCn('cnapp');
  if (origin.includes('cnprd'))                       return buildHostCn('cnprd');

  return buildHost('sandbox');
}

function buildHost(stage: string): Host {
  return {
    url: `https://lscreen-portal-backend-${stage}.apps.mega.cariad.cloud`,
    stage: stage,
  };
}

function buildHostCn(stage: string): Host {
  return {
    url: `https://lscreen-portal-backend-${ stage }.apps.mega.vwautocloud.cn`,
    stage: stage,
  };
}
