import * as React from 'react';
import { DataGrid, GridActionsCellItem, gridClasses, GridColDef, GridSortModel } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import BrandSelect from '../components/BrandSelect';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Brand } from '../../model/Brand';
import { useContext, useEffect, useState } from 'react';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import { indigo } from '@mui/material/colors';
import MESSAGES from '../../constants/en.json';
import LicenseContentPackageController from '../../Controller/LicenseContentPackageController';
import { AuthContext } from '../context/AuthContext';
import { LicenseContentMetadata } from '../../model/LicenseContentMetadata';
import { MessageContext } from '../context/MessageContext';
import Tooltip from '@mui/material/Tooltip';
import StageUpDialog from './dialogs/StageUpDialog';
import InstallDialog from './dialogs/InstallDialog';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import DeleteIcon from '@mui/icons-material/Delete';
import getHost from '../../Controller/GetHost';
import { handleNetworkError, handleResponse } from '../../utils/Utils';
import DeleteRowDialog from './dialogs/DeleteRowDialog';
import Typography from '@mui/material/Typography';
import { Download } from '@mui/icons-material';
import UploadButton from '../components/UploadButton';

export const iconButtonStyle = {
  padding: 0,
  color: indigo[300],
  fontSize: '48px',
  '&.Mui-disabled': {
    color: 'white',
    fontSize: '32px',
    padding: '8px',
  },
};

export default function ContentPackages() {

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const [searchParams] = useSearchParams();
  const oem: Brand = searchParams.get('brand') as Brand;

  const [loadingMessage, setLoadingMessage] = useState<string | undefined>();
  const [rowData, setRowData] = useState<LicenseContentMetadata[]>([]);
  const [idToInstall, setIdToInstall] = useState<string | undefined>('');
  const [idToStageUp, setIdToStageUp] = useState<string | undefined>('');
  const [idToDelete, setIdToDelete] = useState<string | undefined>('');
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'creation-date',
      sort: 'desc',
    },
  ]);

  const licenseContentPackageController = new LicenseContentPackageController();

  useEffect(() => {
    async function loadAllData() {
      if (!oem) return;
      setLoadingMessage('loading data');
      await loadContentPackageMetadata();
    }

    loadAllData()
      .finally(() => setLoadingMessage(undefined));
  }, [oem]);

  async function loadContentPackageMetadata() {
    console.debug('loading column definitions');

    licenseContentPackageController.getAllContentPackagesForOem(oem, authContext.getUser()?.accessToken!)
      .then(async (response) => {
        if (!response.ok) throw new Error(response.statusText);

        const metadataList: LicenseContentMetadata[] = await response.json();
        if (!metadataList) {
          setRowData([]);
          messageContext.addWarning(MESSAGES.WARNING.NO_DATA);
          return;
        }

        setRowData(metadataList);
      })
      .catch((error) => messageContext.addError(MESSAGES.ERROR.GET_LANGUAGES + ' ' + error.message));
  }

  function installToCurrentStage() {
    setLoadingMessage('installing content package');

    licenseContentPackageController.install(oem, idToInstall!, authContext.getUser()?.accessToken!)
      .then((response) => handleResponse(messageContext, response, MESSAGES.SUCCESS.INSTALL, MESSAGES.ERROR.INSTALL))
      .catch((error) => handleNetworkError(messageContext, MESSAGES.ERROR.INSTALL + ' ' + error.message))
      .then(async () => loadContentPackageMetadata())
      .finally(() => {
        setLoadingMessage(undefined);
        setIdToInstall(undefined);
      });  
  }

  function stageUpToNextStage() {
    setLoadingMessage('staging up content package');

    licenseContentPackageController.stageUp(oem, idToStageUp!, authContext.getUser()?.accessToken!)
      .then((response) => handleResponse(messageContext, response, MESSAGES.SUCCESS.STAGE_UP, MESSAGES.ERROR.STAGE_UP))
      .catch((error) => handleNetworkError(messageContext, MESSAGES.ERROR.STAGE_UP + ' ' + error.message))
      .then(async () => loadContentPackageMetadata())
      .finally(() => {
        setLoadingMessage(undefined);
        setIdToStageUp(undefined);
      });
  }

  function deleteContentPackage() {
    setLoadingMessage('deleting content package');
    licenseContentPackageController.delete(oem, idToDelete!, authContext.getUser()?.accessToken!)
      .then((response) => handleResponse(messageContext, response, MESSAGES.SUCCESS.DELETE_ENTRY + idToDelete, MESSAGES.ERROR.DELETE_ENTRY + idToDelete))
      .catch((error) => handleNetworkError(messageContext, MESSAGES.ERROR.DELETE_ENTRY + idToDelete + ' ' + error.message))
      .then(async () => loadContentPackageMetadata())
      .finally(() => {
        setLoadingMessage(undefined);
        setIdToDelete(undefined);
      });

  }

  function uploadContentPackage(file: File) {
    messageContext.addInfo('Uploading ' + file.name);

    const idToUpload = file.name.replace('.lscree', '');

    setLoadingMessage('uploading content package');
    licenseContentPackageController.upload(oem, idToUpload, file, authContext.getUser()?.accessToken!)
      .then((response) => handleResponse(messageContext, response, MESSAGES.SUCCESS.UPLOAD + idToUpload, MESSAGES.ERROR.UPLOAD + idToUpload))
      .catch((error) => handleNetworkError(messageContext, MESSAGES.ERROR.UPLOAD + idToUpload + ' ' + error.message))
      .then(async () => loadContentPackageMetadata())
      .finally(() => {
        setLoadingMessage(undefined);
        setIdToDelete(undefined);
      });
  }

  async function downloadContentPackage(id: string) {
    try {
      const response = await licenseContentPackageController.download(oem, id, authContext.getUser()?.accessToken!);

      if (!response.ok) {
        messageContext.addError(MESSAGES.ERROR.DOWNLOAD + id + ' Status: ' + response.status + ' ' + (await response.json()).message);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // add temporary download link to trigger download
      const a = document.createElement('a');
      a.href = url;
      a.download = id + '.lscree';
      document.body.appendChild(a);
      a.click();

      // remove temporary download link
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      messageContext.addError(MESSAGES.ERROR.DOWNLOAD + id);
    }
  }

  function dateFormatter(unixTimeSeconds: string) : string {
    if (unixTimeSeconds === '') {
      return '';
    } else {
      return new Date(1000 * parseInt(unixTimeSeconds)).toLocaleDateString();
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      width: 120,
      getActions: (params) => [
        <GridActionsCellItem
          key={0}
          icon={
            <Tooltip title="Install this content package to the current stage">
              <InstallDesktopIcon/>
            </Tooltip>
          }
          data-testid={'install-button-' + params.row.id}
          label="Install"
          onClick={() => setIdToInstall((params.row as LicenseContentMetadata).id)}
        />,
        <GridActionsCellItem
          key={0}
          icon={
            <Tooltip title="Stage up this content package to the next stage">
              <MoveUpIcon/>
            </Tooltip>
          }
          data-testid={'stage-up-button-' + params.row.id}
          label="Stage up"
          onClick={() => setIdToStageUp(params.row.id)}
          disabled={!!(params.row as LicenseContentMetadata).stager || getHost().stage === 'prod'}
        />,
      ],
    },
    { field: 'id',                    headerName: 'ID',           flex: 1 },
    { field: 'creator',               headerName: 'Created By',   flex: 1 },
    { field: 'creation-date',         headerName: 'Created On',   flex: 1, valueFormatter: dateFormatter },
    { field: 'stager',                headerName: 'Staged By',    flex: 1 },
    { field: 'staging-date',          headerName: 'Staged On',    flex: 1, valueFormatter: dateFormatter },
    { field: 'installer',             headerName: 'Installed By', flex: 1 },
    { field: 'installation-date',     headerName: 'Installed On', flex: 1, valueFormatter: dateFormatter },
    {
      field: 'action_delete',
      type: 'actions',
      width: 120,
      getActions: (params) => [
        <GridActionsCellItem
          key={0}
          icon={
              <Tooltip title="Download this content package">
                  <Download/>
              </Tooltip>
          }
          data-testid={'download-button-' + params.row.id}
          label="Download"
          onClick={()=> downloadContentPackage(params.row.id)}
          disabled={
            getHost().stage === 'staging' ||
            getHost().stage === 'prod' ||
            getHost().stage === 'cnapp' ||
            getHost().stage === 'cnprd'
          }
        />,
        <GridActionsCellItem
          key={0}
          icon={
            <Tooltip title="Delete this content package">
              <DeleteIcon color="error"/>
            </Tooltip>
          }
          data-testid={'delete-button-' + params.row.id}
          label="Delete"
          onClick={()=> setIdToDelete(params.row.id)}
        />,
      ],
    },
  ];

  const noFocusOutlineStyle: React.CSSProperties = {
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
      {
        outline: 'none',
      },
    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
      {
        outline: 'none',
      },
  };

  return (
    <Box>
      <Grid container sx={ {
        display: 'flex', gap: '8px', flexDirection: 'column', padding: '8px', marginTop: '24px',
      } }>
        <Grid container spacing={1}>
            <Grid item>
                <BrandSelect/>
            </Grid>
            <Grid item>
                <UploadButton
                    disabled={!oem ||
                      getHost().stage === 'staging' ||
                      getHost().stage === 'prod' ||
                      getHost().stage === 'cnapp' ||
                      getHost().stage === 'cnprd'
                    }
                    onUpload={uploadContentPackage}
                />
            </Grid>
        </Grid>
        <Grid container>
          <Grid item sx={ {
            width: '100%',
          } }>
            <DataGrid
              sx={noFocusOutlineStyle}
              autosizeOnMount={true}
              disableColumnResize={true}
              disableRowSelectionOnClick={true}
              data-testid="content-page-table"
              columns={ columns }
              rows={ rowData }
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
              initialState={ {
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              } }
              pageSizeOptions={ [5, 10] }
            />
          </Grid>
        </Grid>
      </Grid>
      <StageUpDialog
        idToStageUp={idToStageUp}
        onStageUp={stageUpToNextStage}
        onClose={() => setIdToStageUp(undefined)}
      />
      <InstallDialog
        idToInstall={idToInstall}
        onInstall={installToCurrentStage}
        onClose={() => setIdToInstall(undefined)}
      />
      <DeleteRowDialog
        idToDelete={idToDelete}
        onDelete={deleteContentPackage}
        onClose={() => setIdToDelete(undefined)}
      />
      <Backdrop
        open={!!loadingMessage}
        sx={{
          color: '#fff',
          zIndex: 999999,
          backdropFilter: 'blur(5px)',
        }}

      >
              <Typography style={{ marginRight: '20px' }}>
                { loadingMessage }
              </Typography>
              <CircularProgress color="inherit"/>
      </Backdrop>
    </Box>
  );
}